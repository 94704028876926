import {
    FunctionComponent,
    useEffect,
    useState
} from "react";
import {
    serverStatusInfo,
    statusLogos,
    STATUS_DEFAULT,
    STATUS_FAIL,
    STATUS_PASS,
    STATUS_PENDING
} from "../consts";
import {
    ServerStatus,
    TestFlowStatus
} from "../type";
import { ReactComponent as QuestionMark } from '../svg/QuestionMark.svg'
import { OverlayTrigger, Tooltip } from "react-bootstrap";


import { isBrowserSupported } from "../utility";

const testTitle = "The use of a supported browser";
const userInstractionTitle = "For the best experience with CloudPrint please download the latest version of Chrome, Edge, Firefox or Safari.";
const tooltipMessage = "CloudPrint is tested using Chrome, Edge, Firefox and Safari. For the best experience we recommend using one of the latest 2 versions of these browsers.";

interface IPropBrowserTest {
    testStatus: TestFlowStatus,
    runTestId: number | null,
    logInfoRef: React.MutableRefObject<string[] | undefined>
    completeTest: () => void,
}

const BrowserTest: FunctionComponent<IPropBrowserTest> = ({
    testStatus,
    runTestId,
    logInfoRef,
    completeTest
}) => {

    const [testLogo, setTestLogo] = useState<React.ReactNode>(statusLogos[STATUS_DEFAULT]);
    const [statusLabel, setStatusLabel] = useState<ServerStatus>(ServerStatus.Default);
    const [isSupported, setIsSupported] = useState<Boolean>(true);

    const setPendingStatus = () => {
        setTestLogo(statusLogos[STATUS_PENDING]);
        setStatusLabel(ServerStatus.Pending);
    }

    const setPassStatus = () => {
        setTestLogo(statusLogos[STATUS_PASS]);
        setStatusLabel(ServerStatus.Pass);
        setIsSupported(true);
    }

    const setFailStatus = () => {
        setTestLogo(statusLogos[STATUS_FAIL]);
        setStatusLabel(ServerStatus.Fail);
        setIsSupported(false);
    }

    const setInitStatus = () => {
        setTestLogo(statusLogos[STATUS_DEFAULT]);
        setStatusLabel(ServerStatus.Default);
    }

    useEffect(() => {
        setIsSupported(true);
    }, [runTestId]);

    useEffect(() => {

        async function conductBrowserTest() {

            if (testStatus === TestFlowStatus.Init)
                setInitStatus();

            if (testStatus !== TestFlowStatus.Pending)
                return;

            setPendingStatus();

            if (isBrowserSupported(navigator.userAgent)) {
                setPassStatus();
            } else {
                setFailStatus();
                logInfoRef.current?.push(userInstractionTitle);
            }

            completeTest();
        }

        conductBrowserTest();
    }, [testStatus]);

    return (
        <>
            <div className="test-title">
                <span className='test-status-icon'>
                    {testLogo}
                </span>
                <span className="test-text">
                    {testTitle}
                </span>
                <OverlayTrigger
                    placement={'right'}
                    overlay={
                        <Tooltip id="tooltip-message">
                            {tooltipMessage}
                        </Tooltip>
                    }
                >
                    <span className="icon">
                        <QuestionMark />
                    </span>
                </OverlayTrigger>

                <span
                    style={serverStatusInfo[statusLabel].styles}
                    className="test-status-label"
                >
                    {serverStatusInfo[statusLabel].label}
                </span>
            </div>
            {!isSupported &&
                <div className="not-supported-browser user-instraction">
                    <h1>Instructions:</h1>
                    <p>{userInstractionTitle}</p>
                </div>
            }
        </>
    )
}

export default BrowserTest;

