import { DomainStatus, ServerStatus, TestFlows, TestFlowStatus } from "./type";
import { customAlphabet } from 'nanoid';
import { matchesUA } from 'browserslist-useragent';

const formatNumber = (date: number) => {
    return (date < 10) ? `0${date}` : date;
}

export const dateFormat = (currDate?: Date | null) => {

    if (!currDate)
        return '0/0/0 0:0:0';

    let month = formatNumber(currDate.getMonth() + 1);
    let date = formatNumber(currDate.getDate());
    let year = formatNumber(currDate.getFullYear());
    let hours = formatNumber(currDate.getHours());
    let minutes = formatNumber(currDate.getMinutes());
    let seconds = formatNumber(currDate.getSeconds());

    return `${month}/${date}/${year}, ${hours}:${minutes}:${seconds}`;
}

export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const isTestCompleted = (domainsStatus: DomainStatus) => {
    const statuses = Object.values(domainsStatus)
        .map(domains => domains.status);

    const elementsWithCompletedstatuses = statuses.filter(status =>
        status === ServerStatus.Fail || status === ServerStatus.Pass);

    return statuses.length === elementsWithCompletedstatuses.length;
}

export const isDomainTestPassed = (domainsStatus: DomainStatus) => {
    const statuses = Object.values(domainsStatus)
        .map(domains => domains.status);

    return !statuses.includes(ServerStatus.Fail);
}

export const getUrl = (domain: string, pathCheck: string) => {
    return `https://${domain}${pathCheck}`;
}

export const download = (filename: string, text: string) => {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export const getFileName = () => {
    const nanoid = customAlphabet('1234567890abcdef', 10)
    return `log_output_${nanoid(10)}`;
}

export const isTestFlowsCompleted = (testFlows: TestFlows): Boolean => {
    const testFlowsStatuses = Object.values(testFlows)
        .map(testFlows => testFlows.status);

    const testFlowsCompleted = testFlowsStatuses
        .filter(status => status === TestFlowStatus.Completed);

    return testFlowsStatuses.length === testFlowsCompleted.length;
}

export const isTestFlowsPending = (testFlows: TestFlows): Boolean => {
    const testFlowsStatuses = Object.values(testFlows)
        .map(testFlows => testFlows.status);

    return testFlowsStatuses.includes(TestFlowStatus.Pending);
}

export const isBrowserSupported = (userAgentString: string): boolean => {

    return matchesUA(userAgentString,
        {
            browsers: ['last 2 safari version',
                'last 2 chrome version',
                'last 2 firefox version',
                'last 2 edge version']
        });
}

export const getDomainStatusMessage = (domain: string) => {
    return `${dateFormat(new Date())}: ${domain} fail`;
}

export const getLastUpdateMassage = (date: Date) => {
    return `Last update: ${dateFormat(date)}`;
}

export const getLogFileText = (testLogs: string[] | undefined) => {
    if (!testLogs) return "";
    return testLogs.reduce((prevLog, log) => prevLog + `\n${log}`);
}