import NetConnectivityTestPage from "./Components/NetConnectivityTestPage";
import dotenv from "dotenv";

dotenv.config();

function App() {
  return (
    <div className="App">
      <NetConnectivityTestPage />
    </div>
  );
}

export default App;
