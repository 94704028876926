import { DomainStatus, IDomainInfo } from "./type";
import { env } from './env'


//Use staging values by default

export const DEFAULT_DOMAINS = {
  CLOUDAUTH_DOMAIN:
    env.REACT_APP_CLOUDAUTH_DOMAIN || "cloudauth.mbot.me",
  REFLECTOR_DOMAIN:
    env.REACT_APP_REFLECTOR_DOMAIN || "reflector-staging.makerbot.com",
  THINGIVERSE_API_DOMAIN:
    env.REACT_APP_THINGIVERSE_API_DOMAIN ||
    "api-staging.thingiverse.com",
  CLOUDSLICER_DOMAIN:
    env.REACT_APP_CLOUDSLICER_DOMAIN ||
    "cloudslicer-staging.makerbot.com",
  WATCHTOWER_DOMAIN:
    env.REACT_APP_WATCHTOWER_DOMAIN ||
    "watchtower-staging.makerbot.com",
  THINGIVERSE_DOMAIN:
    env.REACT_APP_THINGIVERSE_DOMAIN || "staging.thingiverse.com",
  CLOUDPRINT_DOMAIN:
    env.REACT_APP_CLOUDPRINT_DOMAIN || "cloudprint.mbot.me",
  ONION_DOMAIN: env.REACT_APP_ONION_DOMAIN || "login.mbot.me",
  SCHEMA_SETTINGS_DOMAIN:
    env.REACT_APP_SCHEMA_SETTINGS_DOMAIN || "schema-settings.mbot.me",
  RECONSTRUCTOR_DOMAIN:
    env.REACT_APP_RECONSTRUCTOR_DOMAIN || "reconstructor.mbot.me",
  PRINT_PREP_DOMAIN: env.REACT_APP_PRINT_PREP_DOMAIN || "print.mbot.me",
  SIGNATURE_DOMAIN:
    env.REACT_APP_SIGNATURE_DOMAIN || "signature.mbot.me",
  HOOPS_DOMAIN: env.REACT_APP_HOOPS_DOMAIN || "hoops.mbot.me",
};

export const printersDomainChecklist: IDomainInfo[] = [
  {
    domain: DEFAULT_DOMAINS.CLOUDAUTH_DOMAIN,
    pathCheck: "/healthcheck",
  },
  {
    domain: DEFAULT_DOMAINS.REFLECTOR_DOMAIN,
    pathCheck: "/healthcheck",
  },
  {
    domain: DEFAULT_DOMAINS.THINGIVERSE_API_DOMAIN,
    pathCheck: "/healthz.php",
  },
  {
    domain: DEFAULT_DOMAINS.CLOUDSLICER_DOMAIN,
    pathCheck: "/healthcheck",
  },
  {
    domain: DEFAULT_DOMAINS.WATCHTOWER_DOMAIN,
    pathCheck: "",
  },
  {
    domain: "storage.googleapis.com", //left as it is
    pathCheck: "/healthcheck",
  },
  {
    domain: DEFAULT_DOMAINS.THINGIVERSE_DOMAIN,
    pathCheck: "/healthz.php",
  },
  {
    domain: DEFAULT_DOMAINS.CLOUDPRINT_DOMAIN,
    pathCheck: "/healthcheck",
  },
  {
    domain: DEFAULT_DOMAINS.ONION_DOMAIN,
    pathCheck: "/healthcheck",
  },
];

export const cloudPrintDomainChecklist: IDomainInfo[] = [
  {
    domain: DEFAULT_DOMAINS.SCHEMA_SETTINGS_DOMAIN,
    pathCheck: "/healthcheck",
  },
  {
    domain: DEFAULT_DOMAINS.RECONSTRUCTOR_DOMAIN,
    pathCheck: "/healthcheck",
  },
  {
    domain: DEFAULT_DOMAINS.PRINT_PREP_DOMAIN,
    pathCheck: "/healthcheck",
  },
  {
    domain: DEFAULT_DOMAINS.SIGNATURE_DOMAIN,
    pathCheck: "/healthcheck",
  },
  {
    domain: DEFAULT_DOMAINS.HOOPS_DOMAIN,
    pathCheck: "/healthcheck",
  },
];

export const initPrintersDomain: DomainStatus = {
  [DEFAULT_DOMAINS.CLOUDAUTH_DOMAIN]: { status: null, lastUpdate: null },
  [DEFAULT_DOMAINS.REFLECTOR_DOMAIN]: { status: null, lastUpdate: null },
  [DEFAULT_DOMAINS.THINGIVERSE_API_DOMAIN]: { status: null, lastUpdate: null },
  [DEFAULT_DOMAINS.CLOUDSLICER_DOMAIN]: { status: null, lastUpdate: null },
  [DEFAULT_DOMAINS.WATCHTOWER_DOMAIN]: { status: null, lastUpdate: null },
  "storage.googleapis.com": { status: null, lastUpdate: null }, //left as is
  [DEFAULT_DOMAINS.THINGIVERSE_DOMAIN]: { status: null, lastUpdate: null },
  [DEFAULT_DOMAINS.CLOUDPRINT_DOMAIN]: { status: null, lastUpdate: null },
  [DEFAULT_DOMAINS.ONION_DOMAIN]: { status: null, lastUpdate: null },
};

export const initCloudPrintDomain: DomainStatus = {
  [DEFAULT_DOMAINS.SCHEMA_SETTINGS_DOMAIN]: { status: null, lastUpdate: null },
  [DEFAULT_DOMAINS.RECONSTRUCTOR_DOMAIN]: { status: null, lastUpdate: null },
  [DEFAULT_DOMAINS.PRINT_PREP_DOMAIN]: { status: null, lastUpdate: null },
  [DEFAULT_DOMAINS.SIGNATURE_DOMAIN]: { status: null, lastUpdate: null },
  [DEFAULT_DOMAINS.HOOPS_DOMAIN]: { status: null, lastUpdate: null },
};
