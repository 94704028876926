declare global {
    interface Window {
      env: any
    }
  }
  
  // change with your own variables
  type EnvType = {
    REACT_APP_CLOUDAUTH_DOMAIN: string,
    REACT_APP_REFLECTOR_DOMAIN: string,
    REACT_APP_THINGIVERSE_API_DOMAIN: string,
    REACT_APP_CLOUDSLICER_DOMAIN: string,
    REACT_APP_WATCHTOWER_DOMAIN: string,
    REACT_APP_THINGIVERSE_DOMAIN: string,
    REACT_APP_CLOUDPRINT_DOMAIN: string,
    REACT_APP_ONION_DOMAIN: string,
    REACT_APP_SCHEMA_SETTINGS_DOMAIN: string,
    REACT_APP_RECONSTRUCTOR_DOMAIN: string,
    REACT_APP_PRINT_PREP_DOMAIN: string,
    REACT_APP_SIGNATURE_DOMAIN: string,
    REACT_APP_HOOPS_DOMAIN: string,
  }
  export const env: EnvType = { ...process.env, ...window.env }