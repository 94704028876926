import React, { FunctionComponent, useEffect, useState } from "react";
import {
  DomainStatus,
  IDomainInfo,
  ServerStatus,
  TestFlowStatus,
} from "../type";
import {
  getDomainStatusMessage,
  getUrl,
  isDomainTestPassed,
  isTestCompleted,
} from "../utility";
import {
  serverStatusInfo,
  statusLogos,
  STATUS_DEFAULT,
  STATUS_FAIL,
  STATUS_PASS,
  STATUS_PENDING,
} from "../consts";
import { DEFAULT_DOMAINS } from "../config";

interface IPropDomainsTest {
  testStatus: TestFlowStatus;
  checklist: IDomainInfo[];
  initDomains: DomainStatus;
  domainsTestTitle: string;
  runTestId: number | null;
  logInfoRef: React.MutableRefObject<string[] | undefined>;
  completeTest: () => void;
  resetDomains: (fn: (d: DomainStatus) => void) => void;
}

const DomainsTest: FunctionComponent<IPropDomainsTest> = (props) => {
  const {
    testStatus,
    checklist,
    initDomains,
    domainsTestTitle,
    runTestId,
    logInfoRef,
    completeTest,
  } = props;
  const [domainsStatus, setDomainsStatus] = useState<DomainStatus>(initDomains);
  const [testLogo, setTestLogo] = useState<React.ReactNode>(
    statusLogos[STATUS_DEFAULT]
  );
  const [statusLabel, setStatusLabel] = useState<ServerStatus>(
    ServerStatus.Default
  );
  const [failDomainsList, setFailDomainsList] = useState<string[]>([]);

  const clearState = (domains: DomainStatus) => {
    setDomainsStatus(domains);
  };

  props.resetDomains(clearState);
  const updateDomainsStatus = (domain: string, status: ServerStatus) => {
    setDomainsStatus((currDomainsStatus) => {
      let updateDomainStatus: DomainStatus = {
        [domain]: {
          status,
          lastUpdate: new Date(),
        },
      };
      return { ...currDomainsStatus, ...updateDomainStatus };
    });
  };

  const addFailDomainsToList = (domain: string) => {
    setFailDomainsList((currList) => [...currList, domain]);
  };

  const setPendingStatus = () => {
    setTestLogo(statusLogos[STATUS_PENDING]);
    setStatusLabel(ServerStatus.Pending);
  };

  const setPassStatus = () => {
    setTestLogo(statusLogos[STATUS_PASS]);
    setStatusLabel(ServerStatus.Pass);
  };

  const setFailStatus = () => {
    setTestLogo(statusLogos[STATUS_FAIL]);
    setStatusLabel(ServerStatus.Fail);
  };

  const setInitStatus = () => {
    setDomainsStatus(initDomains);
    setTestLogo(statusLogos[STATUS_DEFAULT]);
    setStatusLabel(ServerStatus.Default);
  };

  useEffect(() => {
    async function storageCheck() {
      let result = {
        isSuccess: false,
        message: "",
      };

      const gatewayUrl = `https://${DEFAULT_DOMAINS.CLOUDPRINT_DOMAIN}/`;
      let gatewayResponce = null;
      try {
        gatewayResponce = await fetch(`${gatewayUrl}queue/storage-healthcheck`);
      } catch (err) {
        result.message = "Can not establish connection to Teams";
        return result;
      }

      if (!gatewayResponce || gatewayResponce.status !== 200) {
        result.message = "Can not establish connection to Teams";
        return result;
      }

      const { url } = await gatewayResponce.json();

      let storageResponse = null;
      try {
        storageResponse = await fetch(url);
        if (!storageResponse) throw new Error();

        result.isSuccess = true;

        return result;
      } catch (err) {
        result.message = "storage.googleapis.com is not available";
        return result;
      }
    }

    async function conductDomainsTest() {
      if (testStatus === TestFlowStatus.Init) setInitStatus();

      if (testStatus !== TestFlowStatus.Pending) return;

      setPendingStatus();

      logInfoRef.current?.push(`${domainsTestTitle}:`);

      for (let { domain, pathCheck } of checklist) {
        updateDomainsStatus(domain, ServerStatus.Pending);
        try {
          //the only exception is storage.googleapis.com that goes atually through cloudprint service
          if (domain === "storage.googleapis.com") {
            let checkStatus = await storageCheck();
            if (checkStatus.isSuccess) {
              updateDomainsStatus(domain, ServerStatus.Pass);
              continue;
            }
            updateDomainsStatus(domain, ServerStatus.Fail);
            addFailDomainsToList(domain);
            logInfoRef.current?.push(getDomainStatusMessage(domain));
            continue;
          }

          let responce = await fetch(getUrl(domain, pathCheck));
          if (responce.status === 200) {
            updateDomainsStatus(domain, ServerStatus.Pass);
            continue;
          }
          updateDomainsStatus(domain, ServerStatus.Fail);
          addFailDomainsToList(domain);
          logInfoRef.current?.push(getDomainStatusMessage(domain));
        } catch {
          updateDomainsStatus(domain, ServerStatus.Fail);
          addFailDomainsToList(domain);
          logInfoRef.current?.push(getDomainStatusMessage(domain));
        }
      }
    }
    conductDomainsTest();
  }, [testStatus]);

  useEffect(() => {
    setFailDomainsList([]);
  }, [runTestId]);

  useEffect(() => {
    if (!isTestCompleted(domainsStatus)) return;

    if (isDomainTestPassed(domainsStatus)) setPassStatus();

    if (!isDomainTestPassed(domainsStatus)) setFailStatus();

    completeTest();
  }, [domainsStatus]);

  return (
    <>
      <div className="test-title">
        <span className="test-status-icon">{testLogo}</span>
        <span className="test-text">{domainsTestTitle}</span>
        <span
          style={serverStatusInfo[statusLabel].styles}
          className="test-status-label"
        >
          {serverStatusInfo[statusLabel].label}
        </span>
      </div>
      {isTestCompleted(domainsStatus) && !isDomainTestPassed(domainsStatus) && (
        <div className="fail-domain-list">
          <div className="user-instraction">
            <h1>Instructions:</h1>
            <p>
              Contact your IT admin in order to make the URL accessible from
              your network.
            </p>
          </div>
          {failDomainsList.map((domain, index) => {
            return (
              <div key={`fail-domain-${index}`} className="fail-domain-item">
                <span className="fail-domain-icon">
                  {statusLogos[STATUS_FAIL]}
                </span>
                <span className="fail-domain">{domain}</span>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default DomainsTest;
