import {ReactComponent as MakerBotLogo} from '../svg/MakerBotLogo.svg';

const LogoSection = () => {
    return (
        <>
            <div className="logo-header-section">
                <MakerBotLogo/>
            </div>
        </>
    );
}

export default LogoSection;