import './NetConnectivityTestPage.scss';
import LogoSection from './LogoSection';
import MainSection from './MainSection';

const NetConnectivityTestPage = () => {

    return (
        <div className='net-connectivity-test-page'>
          <LogoSection/>
          <MainSection/>
        </div>
    );
}

export default NetConnectivityTestPage;