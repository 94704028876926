import { FunctionComponent, ReactNode } from "react";

interface IPropButton {
    children: ReactNode;
    enabledStyles: string;
    disabledStyles: string;
    disabled: Boolean,
    onClick: () => void
}

const Button: FunctionComponent<IPropButton> = ({
    children, 
    enabledStyles,
    disabledStyles,
    disabled, 
    onClick
}) => {
    
    if(disabled){
        return (
            <div className={disabledStyles}> 
                {children}
            </div>
        )
    }

    return (
        <div onClick={onClick} className={enabledStyles}> 
            {children}
        </div>
    );
}

export default Button;

