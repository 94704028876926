import { useRef, useState } from "react";
import { testFlows, CLOUD_PRINT_TEST_TITLE, PRINT_TEST_TITLE } from "../consts";
import { DomainStatus, TestFlows, TestFlowStatus } from "../type";
import Button from "./Button";
import LastUpdate from "./LastUpdate";
import { ReactComponent as TestRuningLogo } from "../svg/TestRuning.svg";
import TestFlowSection from "./TestFlowSection";
import DomainsTest from "./DomainsTest";
import { FLOW_ONE, FLOW_TWO, FLOW_THREE } from "../consts";
import BrowserTest from "./BrowserTest";
import {
  download,
  getFileName,
  getLastUpdateMassage,
  getLogFileText,
  isTestFlowsCompleted,
  isTestFlowsPending,
} from "../utility";
import {
  cloudPrintDomainChecklist,
  initCloudPrintDomain,
  initPrintersDomain,
  printersDomainChecklist,
} from "../config";

const MainSection = () => {
  const [lastUpdateDate, setLastUpdateDate] = useState<Date | null>(new Date());
  const [testFlowsStatus, setTestFlowsStatus] = useState<TestFlows>(testFlows);
  const [runTestId, setRunTestId] = useState<number | null>(null);
  const logInfoRef = useRef<string[]>();

  const updateTestFlowsStatus = (
    testNameId: string,
    flowStatus: TestFlowStatus
  ) => {
    setTestFlowsStatus((currTestsStatus) => {
      return {
        ...currTestsStatus,
        ...{ [testNameId]: { status: flowStatus } },
      };
    });
  };

  const resetFlowsStatus = () => {
    setTestFlowsStatus(testFlows);
  };
  const onFlowOneCompleted = () => {
    updateTestFlowsStatus(FLOW_ONE, TestFlowStatus.Completed);
    updateTestFlowsStatus(FLOW_TWO, TestFlowStatus.Pending);
  };

  const onFlowTwoCompleted = () => {
    updateTestFlowsStatus(FLOW_TWO, TestFlowStatus.Completed);
    updateTestFlowsStatus(FLOW_THREE, TestFlowStatus.Pending);
  };

  const onFlowThreeCompleted = () => {
    updateTestFlowsStatus(FLOW_THREE, TestFlowStatus.Completed);

    const lastUpdateDate = new Date();
    setLastUpdateDate(lastUpdateDate);
    logInfoRef.current?.unshift(getLastUpdateMassage(lastUpdateDate));
  };

  const onRunTest = async () => {
    setRunTestId(Math.random());
    clearCloudPrintDomains && clearCloudPrintDomains(initCloudPrintDomain);
    clearPrinterDomains && clearPrinterDomains(initPrintersDomain);
    resetFlowsStatus();
    updateTestFlowsStatus(FLOW_ONE, TestFlowStatus.Pending);
    logInfoRef.current = [];
  };

  const onDownloadTestLogs = async () => {
    download(getFileName(), getLogFileText(logInfoRef.current));
  };

  let clearCloudPrintDomains: null | ((domains: DomainStatus) => void) = null;
  const assignCloudPrintDomains = (
    childClearState: (domains: DomainStatus) => void
  ) => {
    clearCloudPrintDomains = childClearState;
  };

  let clearPrinterDomains: null | ((domains: DomainStatus) => void) = null;
  const assignPrinterDomains = (
    childClearState: (domains: DomainStatus) => void
  ) => {
    clearPrinterDomains = childClearState;
  };

  return (
    <div className="main-section">
      <div className="run-test-info-section">
        <div className="run-test-info-section-header">
          <h1>Network Connectivity Test</h1>
          <h1 className="red">BETA</h1>
        </div>
        <div className="run-test-info">
          <p className="run-test-text-one">
            Run an automated test to determine which of MakerBot's network and
            browser configuration requirements is supported and unsupported by
            you network.
          </p>
          <p className="run-test-text-two">
            The printer needs to be connected to the same network as the
            computer when running this test.
          </p>
          <p className="run-test-text-two red">
            This tool is still under development and unexpected errors might
            occur.
          </p>
        </div>
      </div>

      <div className="run-test-section">
        <div className="run-test-buttons">
          <Button
            enabledStyles="button btn-red"
            disabledStyles="button btn-red-disabled"
            disabled={isTestFlowsPending(testFlowsStatus)}
            onClick={onRunTest}
          >
            {isTestFlowsPending(testFlowsStatus) ? (
              <span className="test-runing-logo">
                <TestRuningLogo id="spinLogo" />
              </span>
            ) : (
              <span>RUN TEST</span>
            )}
          </Button>
          {isTestFlowsCompleted(testFlowsStatus) && (
            <>
              <Button
                enabledStyles="button btn-transparent"
                disabledStyles="button btn-transparent-disabled"
                disabled={!isTestFlowsCompleted(testFlowsStatus)}
                onClick={onDownloadTestLogs}
              >
                DOWNLOAD TEST LOGS
              </Button>
            </>
          )}
        </div>

        <div className="run-test-lastupdate">
          {isTestFlowsCompleted(testFlowsStatus) && (
            <LastUpdate lastUpdateDate={lastUpdateDate} />
          )}
        </div>
      </div>

      <div className="test-section">
        <TestFlowSection>
          <DomainsTest
            completeTest={onFlowOneCompleted}
            resetDomains={assignCloudPrintDomains}
            checklist={cloudPrintDomainChecklist}
            initDomains={initCloudPrintDomain}
            testStatus={testFlowsStatus[FLOW_ONE].status}
            domainsTestTitle={CLOUD_PRINT_TEST_TITLE}
            runTestId={runTestId}
            logInfoRef={logInfoRef}
          />
        </TestFlowSection>

        <TestFlowSection>
          <DomainsTest
            completeTest={onFlowTwoCompleted}
            resetDomains={assignPrinterDomains}
            checklist={printersDomainChecklist}
            initDomains={initPrintersDomain}
            testStatus={testFlowsStatus[FLOW_TWO].status}
            domainsTestTitle={PRINT_TEST_TITLE}
            runTestId={runTestId}
            logInfoRef={logInfoRef}
          />
        </TestFlowSection>

        <TestFlowSection>
          <BrowserTest
            completeTest={onFlowThreeCompleted}
            testStatus={testFlowsStatus[FLOW_THREE].status}
            runTestId={runTestId}
            logInfoRef={logInfoRef}
          />
        </TestFlowSection>
      </div>
    </div>
  );
};

export default MainSection;
