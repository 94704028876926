import { FunctionComponent } from "react";
import { dateFormat } from "../utility";

interface IPropLastUpdate {
    lastUpdateDate: Date | null
}

const LastUpdate: FunctionComponent<IPropLastUpdate> = ({lastUpdateDate}) => {

    if(!lastUpdateDate) return null;

    return (<div className="last-update-info">
        <span>Last update: {` ${dateFormat(lastUpdateDate)}`}</span>
    </div>)
}

export default LastUpdate;