import {
    IServerStatusInfo,
    IServerStatusLogo,
    TestFlows,
    TestFlowStatus 
} from "./type";

import {ReactComponent as FailStatusLogo} from './svg/Fail.svg';
import {ReactComponent as  PassStatusLogo} from './svg/Pass.svg';
import {ReactComponent as PendingStatusLogo} from './svg/Pending.svg'
import {ReactComponent as NotStartedStatusLogo} from './svg/notStarted.svg'

export const  PRINT_TEST_TITLE = "Ability to connect to your printer via CloudPrint";
export const CLOUD_PRINT_TEST_TITLE = "Ability to login and use CloudPrint";

export const STATUS_FAIL = "fail";
export const STATUS_PASS = "pass";
export const STATUS_PENDING = "pending";
export const STATUS_DEFAULT = "default";

export const serverStatusInfo: IServerStatusInfo = {
    [STATUS_PASS]: {
        label:"Pass", 
        styles:{ color: "#5696b9" }
    },
    [STATUS_FAIL]: {
        label:"Fail",
        styles:{ color: "#f7a906" }
    },
    [STATUS_PENDING]: {
        label:"Pending",
        styles:{ color: "#555555" }
    },
    [STATUS_DEFAULT]: {
        label:"",
        styles: { color: "" }
    }
}

export const FLOW_ONE = "flowOne";
export const FLOW_TWO = "flowTwo";
export const FLOW_THREE = "flowThree";

export const testFlows: TestFlows = {
    [FLOW_ONE]: {status: TestFlowStatus.Init},
    [FLOW_TWO]: {status: TestFlowStatus.Init},
    [FLOW_THREE]: {status: TestFlowStatus.Init}
}

export const statusLogos: IServerStatusLogo = {
    [STATUS_FAIL]: <FailStatusLogo/>,
    [STATUS_PASS]: <PassStatusLogo/>,
    [STATUS_PENDING]: <PendingStatusLogo/>,
    [STATUS_DEFAULT]: <NotStartedStatusLogo/>
}



