import { FunctionComponent } from "react";

interface ITestFlowSection {
    children: React.ReactNode;
}

const TestFlowSection: FunctionComponent<ITestFlowSection> = ({ children }) => {
    return (
        <div className='test-flow-section'>
            {children}
        </div>
    );
}

export default TestFlowSection;