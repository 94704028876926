export enum ServerStatus {
    Pass="pass",
    Fail="fail",
    Pending="pending",
    Default="default"
}

export type DomainStatus = {
    [key: string]: {
        status: ServerStatus | null,
        lastUpdate: Date | null
    }
};

export enum TestFlowStatus{
    Completed="completed",
    Pending="pending",
    Init="init"
}

export type TestFlows = {
    [key: string]: { status: TestFlowStatus}
}

export type UpdateDomainsStatus =  ((domain: string, status: ServerStatus | null) => void);
export type DomainStatusContextData = [DomainStatus, UpdateDomainsStatus];
export type UpdateRunTestIndicator = (indicator: number) => void;
export type TestRunIndicatorContextData = [number | null, UpdateRunTestIndicator];


export interface IServerStatusInfo {
   "pass": {label: string, styles: {color: string}},
   "fail": {label: string, styles: {color: string}},
   "pending": {label: string, styles: {color: string}},
   "default": {label: string, styles: {color: string}}
   [key: string]: {label: string , styles: {color: string}}
}

export interface IServerStatusLogo {
    "pass": React.ReactNode,
    "fail": React.ReactNode,
    "pending": React.ReactNode,
    "default": React.ReactNode,
    [key: string]: React.ReactNode
}

export interface IDomainInfo {
    domain: string,
    pathCheck: string
}

